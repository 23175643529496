.total-valid-point-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 0 70px;
}

.total-valid-point {
  font-size: 20px;
  font-weight: bold;
  color: #004098;
}

.total-valid-point-unit {
  font-size: 11px;
  color: #004098;
  margin-left: 2px;
}

.points-by-expiration-date {
  font-size: 11px;
  text-align: center;
  color: #878787;
  margin: 0 70px 20px 70px;
}