.main {
  background-color: #F7F7F7;
  isolation: isolate;
  padding: 20px;
  min-height: calc(100vh - 44px);
}

.total-valid-point-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;
  margin: 0 80px 8px 80px;
}

.total-valid-point {
  font-size: 20px;
  font-weight: bold;
  color: #004098;
}

.total-valid-point-unit {
  font-size: 11px;
  color: #004098;
  margin-left: 2px;
}

.points-by-expiration-date {
  font-size: 11px;
  color: #878787;
  text-align: center;
  margin-bottom: 8px;
}

.line-graduation {
  display: block;
  height: 3px;
  background-image: linear-gradient(270deg, #004098 0%, #E50012 100%);
  margin: 0 65px 40px 65px;
}

.guidance-text {
  font-size: 12px;
  color: #484848;
  text-align: center;
  margin-bottom: 38px;
  font-weight: 500;
}