.back-white {
  padding: 15px;
}

.top-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.point-area {
  display: flex;
  align-items: center;
}

.point-area .icon {
  width: 27px;
}

.point-area .point {
  font-size: 25px;
  font-weight: bold;
  color: #004098;
  margin-left: 3px;
}

.point-area .unit {
  font-size: 19px;
  color: #004098;
}

.tag-area {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.deadline-tag {
  background-color: #484848;
  border-radius: 3px;
  color: #ccccce;
  font-size: 15px;
  line-height: 21px;
  padding: 0 5px;
}

.new-tag {
  background: #e50012;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  width: 58px;
}

.lottery-tag {
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 9999px;
  padding: 0 10px;
}

.immediate-tag {
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 9999px;
  padding: 0 10px;
}

.donation-tag {
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 9999px;
  padding: 0 10px;
}

.fcfs-tag {
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  background-color: #004098;
  border-radius: 9999px;
  padding: 0 10px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  white-space: pre-wrap;
  margin-bottom: 15px;
}

.detail {
  font-size: 14px;
  white-space: pre-wrap;
}

.back-gray {
  background-color: #F7F7F7;
  padding: 15px;
}

.item {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.item:not(:last-of-type) {
  margin-bottom: 5px;
}

.item .head {
  font-weight: bold;
}

.applicationPeriod {
  white-space: pre-wrap;
}

.applicationTerms {
  white-space: pre-wrap;
}

.minnerDitail {
  white-space: pre-wrap;
}

.note {
  white-space: pre-wrap;
}