.error {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}

.sorry {
  font-size: 30px;
  font-weight: lighter;
  text-align: center;
  color: #CCCCCE;
  margin-bottom: 11px;
}

.error-wrap {
  border: 1px solid #E50012;
  padding: 20px 25px;
  margin: 0 35px 18px;
}

.error-message {
  text-align: center;
  color: #E50012;
  white-space: pre-wrap;
}

.error-code {
  text-align: center;
  color: #E50012;
}

.info-wrap {
  flex: 1;
  background-color: #F7F7F7;
  padding: 38px 55px;
}

.info {
  text-align: center;
}