
.list-area {
  background-color: #fff;
  padding: 9px 20px 16px 20px;
  border-radius: 10px;
}

.column-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
  margin-bottom: 10px;
  padding: 0 39px 0px 49px;
}

.column-text {
  font-size: 10px;
}

.list {
  background-color: #F7F7F7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  margin-bottom: 2px;
  padding: 4px 40px;
}

.list-left {
  display: flex;
  justify-content: space-between;
  min-width: 61px;
}

.list-limit {
  font-size: 12px;
}

.list-limit-suffix {
  font-size: 12px;
}

.list-right {
  display: inline-flex;
  align-items: center;
}

.list-point {
  font-size: 12px;
  word-break: break-all;
  text-align: end;
}

.list-point-unit {
  font-size: 12px;
  margin-left: 2px;
}
