.main {
  background-color: #F7F7F7;
  isolation: isolate;
  padding: 20px;
  min-height: calc(100vh - 44px);
}

.total-valid-point-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;
  margin: 0 80px 8px 80px;
}

.total-valid-point {
  font-size: 20px;
  font-weight: bold;
  color: #004098;
}

.total-valid-point-unit {
  font-size: 11px;
  color: #004098;
  margin-left: 2px;
}

.points-by-expiration-date {
  font-size: 11px;
  text-align: center;
  color: #878787;
  margin-bottom: 8px;
}

.link {
  display: block;
  width: auto;
  position: relative;
  font-size: 14px;
  background-color: #f7f7f7;
  border-radius: 9999px;
  margin: 0 auto 6px;
  text-align: center;
  padding: 8px;
  text-decoration: none;
  font-weight: 500;
  line-height: 1.5;
  color: #484848;
  margin: 0 50px 20px;
}

.link::before {
  content: "";
  display: block;
  position: absolute;
  inset: -2px;
  background-image: linear-gradient(45deg, #E50012 0%, #004098 100%);
  border-radius: 9999px;
  z-index: -1;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}