.flow-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flow-heading {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background-color: #004098;
  padding: 6px;
  margin-bottom: 10px;
  font-weight: normal;
  width: 100%;
}

.explanation-body {
  background-color: #ffffff;
  margin: 5px 25px 5px 25px;
  padding: 10px 10px 10px 10px;
  width: 90vw;
}

.explanation-area {
  display: flex;
  align-items: center;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}

.explanation-circle {
  display: flex;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #004098;
}

.explanation-circle span {
  width :68px;
  text-align:center;
  color: #ffffff;
  font-size: 12px;
}

.explanation-text-area {
  width: 252px;
  text-align: center;
}

.explanation-text-font {
  font-size: 16px;
  font-weight: bold;
  color: #004098;
}

.explanation-text-font-red {
  font-size: 16px;
  font-weight: bold;
  color: #E50012;
}

.explanation-circle-area {
  padding-bottom: 40px;
}

.explanation-inner-area {
  width: 252px;
}

.explanation-image-area {
  display: flex;
  align-items: flex-start;
  padding-left: 35px;
  padding-right: 35px;
}

.explanation-image-inner-area {
  display: flex;
  flex-direction: column;
  width: 126px;
}

.image-margin {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.image-size-triangle-down {
  width: 17px;
}

.image-size-card {
  width: 74px;
  margin-left: auto;
  margin-right: auto;
}

.image-text-font {
  font-size: 12px;
  text-align: center;
}

.note-area {
  width: 90vw;
  margin-left: 25px;
  margin-right: 25px;
  line-height: normal;
}

.note-text-font {
  color: #004098;
  font-size: 12px;
  font-weight: bold;
}

.note-text-font-red {
  color: #E50012;
  font-size: 12px;
  font-weight: bold;
}

.input-area {
  width: 90vw;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-left: 25px;
  margin-right: 25px;
  padding-bottom: 25px;
  place-items: center;
}

.input-contents {
  width: 100%;
}

.input-text-font {
  color: #484848;
  font-size: 14.5px;
  font-weight: 400;
  margin-bottom: 10px;
}

.input-text-font-blue {
  padding-top: 5px;
  color: #004098;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
