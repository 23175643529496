.section-heading {
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: #004098;
  padding: 6px;
  font-weight: 500;
}

.section-body {
  background-color: #f7f7f7;
  padding: 10px 35px;
}

.guide-title {
  font-size: 16.5px;
  text-align: center;
  color: #000000;
  padding: 15px 0;
  font-weight: bold;
}

.input-name-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.input-name-wrap input {
  flex-grow: 1;
  min-width: 0;
}

.input-name-wrap input:disabled {
  border: 0.5px solid #fff;
  margin: 0;
}

.text-blue {
  color: #004098;
  font-weight: bold;
}

.hidden-text {
  visibility: hidden;
}

.optional-guide {
  margin-top: 30px;
  background-color: #fff;
  border-radius: 20px;
}

.optional-guide-inner {
  padding: 0px 13px 15px;
}

.optional-guide-note {
  margin-bottom: 10px;
}

.optional-guide h3,
.optional-guide p {
  color: #484848;
  font-size: 14.5px;
  font-weight: normal;
  margin-bottom: auto;
}
