.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.popup.open {
  visibility: visible;
  opacity: 1;
}

.popup-inner {
  position: relative;
  width: 320px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 100px 30px 80px;
}

.complete {
  display: block;
  margin: 0 auto 25px;
}

.text {
  text-align: center;
}

.button {
  display: block;
  font-weight: bold;
  color: #fff;
  background-color: #004098;
  border-radius: 9999px;
  padding: 14px 120px;
}