
.list-area {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
}

.year-select-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 15px;
}

.year-select-left {
  transform: rotate(180deg);
  width: 7px;
}

.year-select-right {
  width: 7px;
}

.list {
  background-color: #F7F7F7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 2px;
  padding: 8px 15px;
}

.list-left {
  width: 60%;
}

.list-date {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 4px;
}

.list-title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
  white-space: pre-wrap;
}

.add .list-title {
  color: #004098;
}

.list-detail {
  font-size: 12px;
  font-weight: normal;
  color: #878787;
  white-space: pre-wrap;
}

.list-point {
  font-size: 16px;
  font-weight: bold;
}

.add .list-point {
  color: #004098;
}

.list-point-unit {
  font-size: 12px;
  font-weight: normal;
}
