header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.page-heading {
  position: relative;
  font-size: 14px;
  text-align: center;
  padding: 35px 0 40px 0;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  padding: 15px;
}

.contract-flow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 90px;
  padding: 0px 30px;
  background-color: #F7F7F7;
}

.contract-circle-blue {
  width: 68px;
  height: 68px;
  background-color: #004098;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.contract-circle-red {
  width: 68px;
  height: 68px;
  background-color: #E50012;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
}

.contract-circle {
  width: 68px;
  height: 68px;
  background-color: #9D9D9D;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
}

.contract-circle-text {
  font-size: 12px;
  color: #FFFFFF;
}
