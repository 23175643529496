header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.page-heading {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  padding: 15px;
}