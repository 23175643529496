.page-body {
  background-color: #f7f7f7;
  min-height: 100vh;
  min-width: 100vw;
}

.note-area {
  padding: 15px 35px 20px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}