.input-title {
  font-size: 14px;
  color: #484848;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}

.input-title:first-of-type {
  margin-top: 0;
}

.required-icon {
  display: inline-flex;
  width: fit-content;
  font-size: 10px;
  line-height: 1;
  vertical-align: text-top;
  color: #fff;
  background-color: #e50012;
  border-radius: 3px;
  padding: 3px 6px;
  margin-left: 8px;
}

.optional-icon {
  display: inline-flex;
  width: fit-content;
  font-size: 10px;
  line-height: 1;
  vertical-align: text-top;
  color: #fff;
  background-color: #9D9D9D;
  border-radius: 3px;
  padding: 3px 6px;
  margin-left: 8px;
}

.w-full {
  width: 100%;
}

.input-readonly {
  border: none;
  font-weight: bold;
}

.input-number::-webkit-inner-spin-button,
.input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  background-color: #FFE4E1;
}
