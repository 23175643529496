.activity {
  padding: 35px 25px 30px 25px;
}

.activity-result-title {
  width: fit-content;
  position: relative;
  font-size: 12px;
  background-color: #fff;
  border-radius: 9999px;
  padding: 0 5px;
  margin: 0 auto 6px;
}

.activity-result-title::before {
  content: "";
  display: block;
  position: absolute;
  inset: -1px;
  background-image: linear-gradient(90deg, #E50012 0%, #004098 100%);
  border-radius: 9999px;
  z-index: -1;
}

.activity-result-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  text-align: center;
  text-decoration: none;
  color: #484848;
  padding: 0 20px;
}

.activity-result-item {
  max-width: calc(100% / 3);
  flex: 1;
}

.activity-result-item-title {
  font-size: 12px;
}

.activity-result-item-data {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  justify-content: center;
  word-break: break-all;
  line-height: 1;
}

.activity-result-item-data>span {
  font-size: 20px;
  font-weight: bold;
}

.activity-result-item-data.point {
  color: #004098;
}

.activity-result-item-data .unit {
  font-size: 11px;
  font-weight: 500;
}

.activity-result-item-icon {
  display: inline-block;
  margin-right: 5px;
}

/* エントリー前 */
.activity-before-entry-complete {
  padding-top: 35px;
  padding-bottom: 30px;
}

.point-reflection-title {
  width: fit-content;
  position: relative;
  font-size: 12px;
  background-color: #fff;
  border-radius: 9999px;
  padding: 0 5px;
  margin: 0 auto 6px;
}

.point-reflection-title::before {
  content: "";
  display: block;
  position: absolute;
  inset: -1px;
  background-image: linear-gradient(90deg, #E50012 0%, #004098 100%);
  border-radius: 9999px;
  z-index: -1;
}

.point-reflection {
  font-size: 12px;
  text-align: center;
  margin-bottom: 27px;
}

.link-sus {
  position: relative;
  display: block;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  color: #484848;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 16px 42px;
  margin: 0 20px;
}

.link-sus::after {
  content: url(../image/arrow_link.svg);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.link-sus .main {
  font-size: 14px;
  font-weight: bold;
  color: #004098;
  letter-spacing: 1.4px;
}

.link-sus .main .red {
  font-weight: bold;
  color: #E50012;
}