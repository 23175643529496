
/* FormParts */

.input-title {
  font-size: 14px;
  color: #484848;
  margin-top: 15px;
  margin-bottom: 5px;
}

.input-title:first-of-type {
  margin-top: 30px;
}

.required-icon {
  display: inline-flex;
  width: fit-content;
  font-size: 10px;
  line-height: 1;
  vertical-align: text-top;
  color: #fff;
  background-color: #e50012;
  border-radius: 3px;
  padding: 3px 6px;
}

.input-text {
  margin-bottom: 50px;
}

.error {
  background-color: #FFE4E1;
}
