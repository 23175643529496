.card {
  text-decoration: none;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.image-wrap {
  display: grid;
  place-items: center;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}

.image-wrap img {
  object-fit: cover;
  min-height: 100%;
}

.new-tag {
  position: absolute;
  display: inline-block;
  background: #E50012;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  border-radius: 3px;
  width: 58px;
  height: 22px;
  text-align: center;
}

.desc {
  background-color: #fff;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  white-space: pre-wrap;
  margin-bottom: 15px;
}

.detail {
  font-size: 12px;
  color: #484848;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  white-space: pre-wrap;
  overflow: hidden;
  margin-bottom: 15px;
}

.period {
  font-size: 12px;
  color: #484848;
  margin-bottom: 15px;
  margin-top: auto;
  white-space: pre-wrap;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.point-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}

.application-point {
  font-size: 14px;
  font-weight: bold;
  color: #004098;
}

.application-point .unit {
  font-size: 11px;
  margin-left: 2px;
}

.deadline-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 15px;
  color: #CCCCCE;
  background-color: #484848;
  border-radius: 3px;
  padding: 0 5px;
}

.lottery-tag {
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 9999px;
  padding: 0 10px;
}

.immediate-tag {
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 9999px;
  padding: 0 10px;
}

.donation-tag {
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 9999px;
  padding: 0 10px;
}

.fcfs-tag {
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  background-color: #004098;
  border-radius: 9999px;
  padding: 0 10px;
}