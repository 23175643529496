.applied {
  background-color: #F7F7F7;
  padding: 30px 65px 35px;
}

.applied-unit-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 10px;
}

.applied-unit {
  font-size: 20px;
  font-weight: bold;
  margin-right: 3px;
}

.line {
  height: 3px;
  background-image: linear-gradient(270deg, #004098 0%, #E50012 100%);
}

.unit {
  font-size: 11px;
}

.bold {
  font-weight: bold;
}
