
.list-area {
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 10px;
}

.no-contact {
  font-size: 14px;
  font-weight: 600;
  color: #484848;
  text-align: center;
  padding: 26px 56px;
}

.list {
  background-color: #F7F7F7;
  display: block;
  margin-bottom: 2px;
  padding: 8px 14px;
}

.list-date {
  font-size: 12px;
  font-weight: normal;
  color: #484848;
  margin-bottom: 4px;
}

.list-class {
  font-size: 12px;
  font-weight: bold;
  color: #004098;
  margin-bottom: 6px;
}

.list-content {
  font-size: 11px;
  font-weight: 600;
  color: #004098;
  margin-bottom: 6px;
  white-space: pre-wrap;
}

.list-notes {
  font-size: 11px;
  font-weight: normal;
  color: #878787;
}
