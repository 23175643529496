.button-area {
  padding: 25px 65px 40px;
}

.text-info {
  text-align: center;
  margin-bottom: 20px;
}

.point-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background-color: #F7F7F7;
  padding: 15px 20px;
  margin-bottom: 25px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 84px;
}

.total-valid-point,
.use-point,
.left-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-valid-point .point,
.left-point .point {
  color: #004098;
}

.point {
  font-size: 20px;
  font-weight: bold;
  margin-right: 3px;
}

.total-valid-point .unit,
.left-point .unit {
  color: #004098;
}

.unit {
  font-size: 11px;
}

.amount-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 190px;
  margin: 0 auto 20px;
}

.decrement,
.increment {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 17px;
  color: #fff;
  border-radius: 5px;
  background-color: #004098;
}

.decrement-disabled,
.increment-disabled {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 17px;
  color: #fff;
  border-radius: 5px;
  background-color: #004098;
  opacity: 0.3;
}

.amount {
  font-size: 20px;
}

.amount.gray {
  color: #CCCCCE;
}

.application-button {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #004098;
  border-radius: 9999px;
  padding: 14px 0;
}

.application-button-disabled {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #004098;
  border-radius: 9999px;
  padding: 14px 0;
  opacity: 0.3;
}