.main {
  background-color: #F7F7F7;
  padding: 35px 20px;
}

.line-graduation {
  display: block;
  height: 3px;
  background-image: linear-gradient(270deg, #004098 0%, #E50012 100%);
  margin-bottom: 20px;
}

.graph-title {
  font-size: 18px;
  letter-spacing: 1.8px;
  color: #004098;
  margin-bottom: 10px;
}

.graph-wrap {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 15px 0;
  margin-bottom: 3px;
}

.year-select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-bottom: 5px;
}

.year-select-prev {
  width: 15px;
  padding: 4px;
  transform: rotate(180deg);
}

.year-select-next {
  width: 15px;
  padding: 4px;
}

.graph-legend {
  margin: 15px auto 0;
}



.table-wrap {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 10px 20px;
  margin-bottom: 35px;
}

.table-heading {
  position: relative;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.3px;
}

.table-heading-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(0deg);
}

.table-heading-arrow.open {
  transform: translateY(-50%) rotate(90deg);
}

.table-content {
  height: 0;
  overflow: hidden;
}

.table-content.open {
  margin-top: 20px;
  height: auto;
}

.row {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0 5px;
}

.row:not(:last-of-type) {
  margin-bottom: 2px;
}

.row.content {
  background-color: #F7F7F7;
  padding: 10px 5px;
}

.row span {
  font-weight: normal;
  font-size: 10px;
  text-align: center;
}

.row .cell-flex {
  display: flex;
  justify-content: space-between;
}

.row>span:nth-of-type(1) {
  width: 42px;

}

.row>span:nth-of-type(2) {
  flex: 1;
}

.row>span:nth-of-type(3) {
  flex: 1;
}

.row>span:nth-of-type(4) {
  flex: 1;
}

.row span.text-performance {
  color: #000;
  font-weight: bold;
}

.row span.text-performance-label {
  color: #000;
  font-weight: normal;
}

.row span.text-performance .unit {
  font-weight: normal;
}

.row span.text-target {
  color: #E55C68;
}

.row span.text-target .label {
  color: #484848;
}

.row span.text-output {
  color: #000;
  font-weight: bold;
}

.row span.text-output .unit {
  font-weight: normal;
}

.row span.text-point {
  color: #004098;
  font-weight: bold;
}

.row span.text-point .unit {
  font-weight: normal;
}

.text-point>img {
  margin-left: 20%;
}


.co2-reduction-icon-wrap {
  width: 15px;
  margin-left: 20%;
}

.co2-reduction-icon-wrap img {
  margin: 0 auto;
}

.co2-info {
  font-size: 10px;
  letter-spacing: normal;
  color: #484848;
  margin-left: 10px;
}