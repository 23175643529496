.content {
    background-color: #F7F7F7;
    padding-bottom: 35px;
}

.sus-info-area {
    padding: 20px 20px 20px;
}

.info-logo-area {
    margin: 12px 0px 21px 0px;
}

.info-logo-image {
    width: 80px;
    height: 84px;
    margin: 0px auto;
}

.sus-info-text {
    text-align: center;
    font: normal normal bold 16px/35px Noto Sans JP;
    letter-spacing: 1.6px;
    color: #484848;
}

.sus-info-sub-text {
    text-align: center;
    font: normal normal medium 14px/20px Noto Sans JP;
    letter-spacing: 0px;
    color: #484848;
    padding-bottom: 9px;
}

.sus-info-bar {
    height: 3px;
    background: transparent linear-gradient(270deg, #004098 0%, #E50012 100%) 0% 0% no-repeat padding-box;
}





.no-prize {
    text-align: center;
    padding: 40px 90px 60px 90px;
}





.slide-wrap-info {
    text-align: center;
    font: normal normal bold 17px/35px Noto Sans JP;
    ;
    letter-spacing: 1.36px;
    color: #004098;
    margin: 20px 20px 10px 20px;
}

.slide-wrap {
    display: flex;
    margin: 100 auto;
    width: 100%;

    overflow-x: scroll;
    padding: 0 20px 20px 20px;
}

.slide-box {
    height: auto;
    width: 24%;
    flex: 0 0 320px;
    position: relative;
    margin-right: 10px;
}

.slide-box-link {
    background-color: #fff;
    color: #222;
    display: grid;
    place-items: center;
    text-decoration: none;
    aspect-ratio: 16 / 11;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.slide-box-image {
    display: block;
    height: auto;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
}

.slide-box-new-tag {
    position: absolute;
    display: inline-block;
    background: #E50012;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 16px;
    line-height: 22px;
    border-radius: 3px;
    width: 58px;
    height: 22px;
    text-align: center;
}

.slide-box-bottom-area {
    position: absolute;
    bottom: 0%;
    left: 0%;
    right: 0%;
    padding: 0 10px 10px 10px;
}

.slide-box-bottom-area-title {
    font-size: 14px;
    color: white;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
    text-shadow: 0 0 5px #000;
}

.slide-box-bottom-image-area {
    display: flex;
    align-items: center;
    gap: 3px;
    width: 100%;
}

.slide-box-bottom-image-area-p-white {
    /* position     : absolute;
    bottom:0%;
    left:0%; */
    filter: drop-shadow(0 0 5px #000);
}

.slide-box-bottom-image-area-point {
    /* position     : absolute;
    bottom:0%;
    left: 8%; */
    font-size: 12px;
    text-shadow: 0 0 5px #000;
}

.slide-box-bottom-image-area-point .point {
    font-size: 14px;
    font-weight: 700;
    color: white;
}

.slide-box-bottom-image-area-point .unit {
    font-size: 11px;
    font-weight: normal;
    color: white;
    margin-left: 2px;
}

.slide-box-bottom-image-area-p-text {
    position: absolute;
    bottom: 0%;
    left: 10%;
}

.slide-box-bottom-image-area-tag {
    /* position     : absolute;
    bottom:0%;
    right:0%; */
    margin-right: 0;
    margin-left: auto;

}





.ten-point-sus-wrap {
    padding: 0 20px 20px 20px;
}

.ten-point-sus {
    position: relative;
    display: flex;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 130px;
    color: black;
    text-decoration: none;
}

.ten-point-sus:last-of-type {
    margin-bottom: 0;
}

.ten-point-sus .image-wrap {
    width: 130px;
    border-radius: 10px 0px 0px 10px;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.ten-point-sus .image {
    display: block;
    height: auto;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
}

.ten-point-sus .desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.ten-point-sus .desc .title {
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
}

.ten-point-sus .desc .detail {
    color: #484848;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
}

.ten-point-sus .desc .attach {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.ten-point-sus .desc .attach .point-wrap {
    display: flex;
    align-items: center;
    gap: 3px;
}

.ten-point-sus .desc .attach .point {
    font-weight: bold;
    color: #004098;
}

.ten-point-sus .desc .attach .point .count {
    font-size: 14px;
    color: #004098;
}

.ten-point-sus .desc .attach .point .unit {
    font-size: 11px;
    color: #004098;
    margin-left: 2px;
}

.link-entry {
    display: block;
    width: fit-content;
    font-size: 14px;
    font-weight: 700;
    color: #484848;
    text-decoration: none;
    position: relative;
    padding-right: 20px;
    padding-bottom: 4px;
    margin: 0 35px 0 auto;
}

.link-entry::after {
    content: url("../image/icon_arrow_gray.svg");
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}





.deadline-list {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 20px;
}

.deadline-list .box {
    flex: 0 0 320px;
    margin: auto 4px;
    background-image: linear-gradient(-45deg, #004098 0%, #004098 49%, #f7f7f7 49%, #f7f7f7 51%, #E50012 51%, #E50012 100%);
    padding: 1px;
    border-radius: 18px;
}

.deadline-list .box-inner {
    background: #f7f7f7;
    padding: 7px;
    border-radius: 17px;
}

.deadline-list .box:nth-of-type(1) {
    margin: auto 4px auto 20px;
}

.deadline-list .box:nth-last-of-type(1) {
    margin: auto 20px auto 4px;
}





.shopping-slide-wrap {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding: 0 20px 20px 20px;
}

.shopping-slide-box {
    display: flex;
    flex-direction: column;
    flex: 0 0 165px;
    text-decoration: none;
    background-color: #fff;
    border-radius: 15px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
}

.shopping-slide-box:last-of-type {
    margin-right: 0;
}

.shopping-slide-new-tag {
    position: absolute;
    display: inline-block;
    background: #E50012;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 16px;
    line-height: 22px;
    border-radius: 3px;
    width: 58px;
    height: 22px;
    text-align: center;
}

.shopping-slide-image-wrap {
    aspect-ratio: 1 / 1;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.shopping-slide-image {
    display: block;
    height: auto;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
}

.shopping-slide-desc {
    padding: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.shopping-slide-title {
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
}

.shopping-slide-detail {
    font-size: 12px;
    color: #484848;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
}

.shopping-slide-bottom {
    display: flex;
    gap: 3px;
    margin: auto 0 0 0;
    align-items: center;
}

.shopping-slide-point {
    font-weight: bold;
}

.shopping-slide-point-unit {
    font-size: 11px;
    margin-left: 2px;
}





.footer {
    padding: 50px 20px;
}

.footer .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
}

.link-sus {
    position: relative;
    display: grid;
    place-items: center;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #484848;
    letter-spacing: 1.2px;
    background-color: #E8E8E8;
    border-radius: 10px;
    height: 65px;
}

.link-sus::after {
    content: url(../image/arrow_link.svg);
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}



.lottery-tag {
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 9999px;
    padding: 0 10px;
}

.immediate-tag {
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 9999px;
    padding: 0 10px;
}

.donation-tag {
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 9999px;
    padding: 0 10px;
}

.fcfs-tag {
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    background-color: #004098;
    border: 1px solid #fff;
    border-radius: 9999px;
    padding: 0 10px;
}