header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.page-heading {
  position: relative;
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  padding: 15px;
}

.guide {
  text-align: center;
  padding: 20px;
}

.error-section {
  padding: 0 30px 20px 30px;
}

.error-wrap {
  color: #E50012;
  border: 1px solid #E50012;
  padding: 15px 20px;
}

.text-blue {
  color: #004098;
}

.mb-11 {
  margin-bottom: 11px;
}

.mb-23 {
  margin-bottom: 23px;
}

.section-heading {
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: #004098;
  padding: 6px;
}

.section-body {
  background-color: #f7f7f7;
  padding: 10px 35px;
}

.input-lastname-wrap,
.input-firstname-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-lastname-wrap {
  margin-bottom: 5px;
}

.input-lastname-wrap input,
.input-firstname-wrap input {
  flex: 1;
}

.input-birth-wrap,
.input-movein-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 3px;
}

.input-birth-wrap input,
.input-movein-wrap input {
  max-width: 80px;
  text-align: center;
}

.input-birth-wrap input:not(:first-of-type),
.input-movein-wrap input:not(:first-of-type) {
  margin-left: 10px;
}

@media screen and (max-width: 389px) {

  .input-birth-wrap input:not(:first-of-type),
  .input-movein-wrap input:not(:first-of-type) {
    margin-left: 4px;
  }
}

.buttonarea {
  display: grid;
  place-items: center;
  background-color: #f7f7f7;
  padding: 30px 35px 256px 35px;
}

.button {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
}

.button-not-click {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
  opacity: 0.3;
}

.input-zip-wrap,
.input-address-wrap,
.input-addressee-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 5px;
}

.input-zip-wrap .label,
.input-address-wrap .label,
.input-addressee-wrap .label {
  width: 28px;
  font-weight: bold;
  color: #004098;
}

.input-address-wrap input,
.input-addressee-wrap input {
  flex: 1;
}

.input-zip-wrap input {
  flex-basis: 40%;
  width: 130px;
}

.input-address-wrap input:disabled,
.input-address-wrap textarea:disabled,
.input-addressee-wrap input:disabled,
.input-zip-wrap input:disabled {
  border: 0.5px solid #fff;
  margin: 0;
}

.input-address-wrap select {
  margin-bottom: 0 !important;
  padding-left: 12px !important;
}

.input-residents-wrap,
.input-child-residents-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
}

.input-residents-wrap .label,
.input-child-residents-wrap .label {
  width: 110px;
  font-weight: bold;
  color: #004098;
}

.input-residents-wrap .unit,
.input-child-residents-wrap .unit {
  align-self: end;
  color: #004098;
  margin-bottom: 5px;
}

.radio-usage-classification-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 25px;
  margin-bottom: 5px;
}

.radio-delivery-info-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px 25px;
  margin-bottom: 5px;
}

.energy-vender-id-wrap {
  /* display: none; */
  margin-bottom: 17px;
}

.input-entry-zip-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 5px;
}

.input-entry-zip-wrap .label {
  color: #004098;
  font-weight: 700;
  width: 28px;
}

.input-entry-zip-wrap input {
  width: 130px;
}

.input-entry-state-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 5px;
}

.input-entry-state-wrap .label {
  color: #004098;
  font-weight: 700;
  width: 28px;
}

.input-entry-state-wrap>span:not(.label) {
  width: 130px;
}

.input-entry-state-wrap select {
  width: 100%;
  padding-left: 12px !important;
  margin-bottom: 0 !important;
}

.input-entry-address-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 5px;
}

.input-entry-address-wrap .label {
  color: #004098;
  font-weight: 700;
  width: 28px;
}

.input-entry-address-wrap textarea,
.input-address-wrap textarea {
  flex: 1;
  overflow-wrap: break-word;
}

.input-room-size-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
}

.input-room-size-wrap input {
  width: 84px;
}

.input-room-size-wrap .unit {
  align-self: end;
  color: #004098;
}

.input-room-size-wrap .example {
  margin-left: 18px;
}

.input-room-size-wrap .example span{
  font-size: 14px;
  color: #484848;
}

.input-movein-radio-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 13px;
}

.input-gasSupplyPointNo-info {
  margin-bottom: 17px;
}

.input-gasSupplyPointNo-wrap input {
  width: 100%;
}

.input-entry-zip-wrap input:disabled,
.input-entry-address-wrap textarea:disabled {
  border-color: #fff;
}

.representative-name-attention {
  font-weight: 400;
  margin-bottom: 20px;
}

.detached-house-attention {
  font-weight: 400;
  margin-bottom: 25px;
}

.input-movein-wrap input:disabled {
  border-color: #fff;
}