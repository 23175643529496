.input-title {
  font-size: 14px;
  color: #484848;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: normal;
}

.input-title:first-of-type {
  margin-top: 0;
}

.required-icon {
  display: inline-flex;
  width: fit-content;
  font-size: 10px;
  line-height: 1;
  vertical-align: text-top;
  color: #fff;
  background-color: #e50012;
  border-radius: 3px;
  padding: 3px 6px;
  margin-left: 8px;
}

.w-full {
  width: 100%;
}

.input-readonly {
  padding: 14px 12px;
  margin-bottom: 5px;
  background-color: #fff;
  color: #000;
  white-space: pre-wrap;
}

.error {
  background-color: #FFE4E1;
}

.input-radio {
  display: none;
}

.input-radio-label {
  position: relative;
  display: inline-block;
  font-weight: bold;
  color: #004098;
  padding-left: 30px;
}

.input-radio-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 24px;
  height: 24px;
  background-image: radial-gradient(#fff, #fff 11px, #004098 11px, #004098);
  border-radius: 9999px;
}

.input-radio:checked+.input-radio-label::before {
  background-image: radial-gradient(#004098, #004098 8px, #fff 8px, #fff 11px, #004098 11px, #004098);
}

.select-wrap {
  position: relative;
  display: block;
  width: fit-content;
}

.select-wrap select {
  padding: 12px 40px 12px 25px;
  margin-bottom: 5px;
}

.select-wrap-w-full {
  position: relative;
  display: block;
  width: 100%;
}

.select-wrap-w-full select {
  padding: 12px 40px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  display: block;
  width: 16px;
  height: 14px;
  background-color: #004098;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.select-wrap select:disabled {
  border: .5px solid #fff;
}

.select-wrap select:disabled+.select-arrow {
  background-color: #9D9D9D;
}

.select-wrap-w-full select:disabled {
  border: .5px solid #fff;
}

.select-wrap-w-full select:disabled+.select-arrow {
  background-color: #9D9D9D;
}