.header {
  background-color: #fff;
  padding: 7px 15px;
  position: sticky;
  top: 0;
  z-index: 99;
}

.header-inner {
  height: 43px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.header-wrap {
  display: flex;
  align-items: flex-end;
}

.sus-logo {
  width: 40px;
  margin-left: calc(50vw - 15px - 20px - 143px);
  margin-right: 10px;
}

@media screen and (max-width: 389px) {
  .sus-logo {
    margin-left: calc(195px - 15px - 20px - 143px);
  }
}

.sus-logo-area {
  padding: 20px 25px;
}

.sus-logo-area-inner {
  display: flex;
  align-items: center;
  gap: 60px;
  max-width: 375px;
  margin: 0 auto;
}

.sus-logo-area-inner-after-entry {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 375px;
  margin: 0 auto;
}

.header-point-scrolled {
  font-weight: bold;
  color: #004098;
  display: flex;
  align-items: center;
  gap: 2px;
}

.header-point-unit-scrolled {
  font-size: 11px;
  font-weight: normal;
}

.sus-logo-area-right {
  flex: 1;
}

.sub-header {
  padding-bottom: 30px;
}

.banner {
  width: 100%;
}

.entry-text-before-entry {
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #E50012;
  padding: 5px;
}

.get-100-point {
  font-size: 12px;
  margin-bottom: 12px;
}

.get-100-point .point {
  font-size: 20px;
  font-weight: 700;
  color: #004098;
}

.get-100-point .unit {
  font-size: 14px;
  font-weight: 700;
  color: #004098;
  margin-right: 4px;
}

.link-entry {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #484848;
  text-decoration: none;
  position: relative;
}

.link-entry::after {
  content: url("../image/arrow_link.svg");
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.entry-text-during-entry {
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #004098;
  padding: 5px;
}

.link-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  color: #484848;
  text-decoration: none;
  padding-right: 21px;
  position: relative;
}

.link-point::after {
  content: url("../image/arrow_link.svg");
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.link-point .point {
  font-size: 20px;
  font-weight: bold;
  color: #004098;
}

.link-point .unit {
  font-size: 11px;
  font-weight: 500;
  color: #004098;
}

.point-reflection-title {
  width: fit-content;
  position: relative;
  font-size: 12px;
  background-color: #fff;
  border-radius: 9999px;
  padding: 0 5px;
  margin: 0 auto 6px;
}

.point-reflection-title::before {
  content: "";
  display: block;
  position: absolute;
  inset: -1px;
  background-image: linear-gradient(90deg, #E50012 0%, #004098 100%);
  border-radius: 9999px;
  z-index: -1;
}

.point-reflection {
  font-size: 12px;
  text-align: center;
  margin-bottom: 27px;
}

.link-sus {
  position: relative;
  display: block;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  color: #484848;
  background-color: #F7F7F7;
  border-radius: 10px;
  padding: 16px 42px;
  margin: 0 20px;
}

.link-sus::after {
  content: url(../image/arrow_link.svg);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.link-sus .main {
  font-size: 14px;
  font-weight: bold;
  color: #004098;
  letter-spacing: 1.4px;
}

.link-sus .main .red {
  font-weight: bold;
  color: #E50012;
}

.point-expiration {
  font-size: 11px;
  font-weight: normal;
  color: #878787;
}

.activity-result-wrap {
  padding: 0 25px;
}

.activity-result-title {
  width: fit-content;
  position: relative;
  font-size: 12px;
  background-color: #fff;
  border-radius: 9999px;
  padding: 0 5px;
  margin: 0 auto 6px;
}

.activity-result-title::before {
  content: "";
  display: block;
  position: absolute;
  inset: -1px;
  background-image: linear-gradient(90deg, #E50012 0%, #004098 100%);
  border-radius: 9999px;
  z-index: -1;
}

.activity-result-link {
  position: relative;
  max-width: 375px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  text-align: center;
  text-decoration: none;
  color: #484848;
  margin: 0 auto;
  padding: 0 20px;
}

.activity-result-link::after {
  content: url(../image/arrow_link.svg);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.activity-result-item {
  max-width: calc(100% / 3);
  flex: 1;
}

.activity-result-item-title {
  font-size: 12px;
}

.activity-result-item-data {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  word-break: break-all;
  line-height: 1;
}

.activity-result-item-data>span {
  font-size: 20px;
  font-weight: bold;
}

.activity-result-item-data.point {
  color: #004098;
}

.activity-result-item-data .unit {
  font-size: 11px;
  font-weight: 500;
}

.activity-result-item-icon {
  display: inline-block;
  margin-right: 5px;
}

.hamburger {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.73);
  transition: all 0.3s ease-in-out;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
}

.hamburger.open {
  visibility: visible;
  opacity: 1;
}

.hamburger-wrap {
  position: relative;
  width: 260px;
  height: 100%;
  background-color: #fff;
  padding: 60px 24px 24px 24px;
  margin: 0 0 0 auto;
  overflow: scroll;
}

.hamburger-open,
.hamburger-close {
  margin-bottom: 6px;
  z-index: 101;
}

.hamburger-logo-sus {
  display: block;
  margin: 0 auto 65px;
}

.menu-wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.menu-link {
  position: relative;
  color: #484848;
  font-weight: 500;
  text-decoration: none;
  padding-right: 10px;
}

.menu-link::after {
  content: url(../image/arrow_link.svg);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.entry-flow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 90px;
  padding: 0px 30px;
  background-color: #F7F7F7;
}

.entry-circle-blue {
  width: 68px;
  height: 68px;
  background-color: #004098;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.entry-circle-red {
  width: 68px;
  height: 68px;
  background-color: #E50012;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
}

.entry-circle {
  width: 68px;
  height: 68px;
  background-color: #9D9D9D;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
}

.entry-circle-text {
  font-size: 12px;
  color: #FFFFFF;
}

.start-guide {
  background-color: #004098;
  text-align: center;
  padding: 25px 16px 28px 16px;
}

.start-guide-before-entry {
  background-color: #004098;
  text-align: center;
  padding: 15px 16px 28px 16px;
}

.start-guide-inner-area {
  padding: 17px 27px;
  background-color: #FFFFFF;
}

.start-guide-margin {
  background-color: #FFFFFF;
  border-radius: 35px;
}

.start-guide-inner {
  padding: 17px 27px 23px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-guide-inner-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-guide-text-blue {
  color: #004098;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.start-guide-text-red {
  color: #E50012;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.start-guide-text-gray {
  font-weight: medium;
  font-size: 14px;
  line-height: 24px;
  max-width: 293px;
  text-align: left;
}

.start-guide-point-red {
  font: normal normal bold 18px/24px Noto Sans;
  color: #E50012;
}

.start-guide-text-white {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 11px;
}

.start-guide-image-area {
  width: 99vw;
  max-width: 386px;
}

.start-guide-rule {
  display: flex;
  align-items: center;
  flex-direction: column;
}

  .start-guide-rule-detail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

.note {
  width: 275px;
  margin-top: 17px;
}

.note-text {
  font-size: 13px;
  color: #484848;
  text-align: left;
}

.start-guide-inner-change {
  margin-top: 28.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-guide-border {
  width: 304px;
  height: 0px;
  border: 1px solid #004098;
  border-radius: 2px;
  margin-bottom: 3px;
}

.entry-link-area {
  width: 286px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #E50012;
  box-shadow: 0px 3px 8px #0000004D;
  text-align: center;
  border-radius: 35px;
}

.entry-link-text {
  text-align: center;
  color: #FFFFFF;
  text-decoration: underline;
  text-decoration-color: #e50012;
}

.rule-link-area {
  width: 286px;
  height: 64px;
  margin-top: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #004098;
  box-shadow: 0px 3px 8px #0000004D;
  text-align: center;
  border-radius: 35px;
}

.rule-link-text {
  text-align: center;
  color: #FFFFFF;
  font: normal normal bold 17px/20px Noto Sans JP;
}

.entry-link-arrow {
  position: absolute;
  right: 20px;
  width: 24px;
  height: 24px;
}

.start-guide-text-strong-point {
  font: normal normal 600 29px/39px Noto Sans;
  color: #004098;
  margin-right: 4.73px;
}

.start-guide-text-strong-blue{
  font: normal normal 600 20px/27px Noto Sans;
  color: #004098;
  margin-right: 7px;

}

.start-guide-text-small-gray{
  font: normal normal bold 14px/35px Noto Sans JP;
  color: #484848;
  margin-right: 5px;

}

.start-guide-text-strong-gray{
  font: normal normal bold 20px/35px Noto Sans JP;
  color: #484848;
}

.start-guide-button{
  margin: 15px 0px 26px 0px;
}