.w-full {
  width: 100%;
}
.error {
  background-color: #FFE4E1;
}

.select-wrap {
  position: relative;
  display: block;
  width: fit-content;
}

.select-wrap select {
  padding: 12px 40px 12px 25px;
  margin-bottom: 5px;
}

.select-wrap-w-full {
  position: relative;
  display: block;
  width: 100%;
}

.select-wrap-w-full select {
  padding: 15px 20px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  display: block;
  width: 16px;
  height: 14px;
  background-color: #004098;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.select-wrap select:disabled {
  border: .5px solid #fff;
}

.select-wrap select:disabled+.select-arrow {
  background-color: #9D9D9D;
}

.select-wrap-w-full select:disabled {
  border: .5px solid #fff;
}

.select-wrap-w-full select:disabled+.select-arrow {
  background-color: #9D9D9D;
}