.button-area {
  display: grid;
  place-items: center;
  padding: 15px 35px 15px 35px;
}

.button {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
  cursor: pointer;
}