@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  color: #484848;
  -webkit-font-smoothing: antialiased;
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  all: unset;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: 0.5px solid #004098;
  padding: 14px 12px;
}

input::placeholder,
button::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: #ccccce;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

p,
span {
  font-size: 14px;
  font-weight: 500;
}

a {
  color: #004098;
}

#root,
#__next {
  isolation: isolate;
}