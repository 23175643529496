/* Page */

.background {
  isolation: isolate;
  background-color: #f7f7f7;
}

/* Header */

header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.page-heading {
  position: relative;
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  padding: 15px;
}

.guide {
  text-align: center;
  padding: 20px;
}

/* Error */

.error-section {
  padding: 0 30px 20px 30px;
}

.error-wrap {
  color: #E50012;
  border: 1px solid #E50012;
  padding: 15px 20px;
  text-align: center;
}

/* Body */

.text-center {
  text-align: center;
}

.link-underline {
  text-decoration: underline;
}

.input-pointAssignmentCode-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
}

.input-pointAssignmentCode-wrap .label {
  width: 28px;
}

.input-pointAssignmentCode-wrap input {
  flex: 1;
}

.section-body {
  background-color: #f7f7f7;
  padding: 40px 35px;
}

/* ButtonArea */

.buttonarea {
  display: grid;
  place-items: center;
  padding: 110px 35px 260px 35px;
}

.history-buttonarea {
  display: grid;
  place-items: center;
  padding: 0px 35px;
  width: 100%;
}

.button {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
  margin-bottom: 35px;
}

.button-not-click {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
  opacity: 0.3;
  margin-bottom: 35px;
}

.history-button {
  width: 100%;
  position: relative;
  font-size: 14px;
  background-color: #f7f7f7;
  border-radius: 9999px;
  margin: 0 auto 6px;
  text-align: center;
  padding: 8px;
  text-decoration: none;
  font-weight: 500;
  line-height: 1.5;
  color: #484848;
}

.history-button::before {
  content: "";
  display: block;
  position: absolute;
  inset: -2px;
  background-image: linear-gradient(45deg, #E50012 0%, #004098 100%);
  border-radius: 9999px;
  z-index: -1;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}