.tags-wrap {
  display: flex;
  gap: 10px;
  padding: 0 20px;
  overflow-x: scroll;
  padding-bottom: 10px;
}

.tag {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #484848;
  border: 1px solid #004098;
  border-radius: 9999px;
  padding: 5px 12px;
  white-space: nowrap;
}

.tag.selected {
  color: #fff;
  background-color: #004098;
}
