.buttonarea {
  display: grid;
  place-items: center;
  background-color: #f7f7f7;
  padding: 43px 37px;
}

.button {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
}

.button-not-click {
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #004098;
  border: none;
  border-radius: 9999px;
  padding: 15px;
  opacity: 0.3;
}
