.sticky-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}