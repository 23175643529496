.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.popup.open {
  visibility: visible;
  opacity: 1;
}

.popup-inner {
  position: relative;
  width: 320px;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 30px 25px;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.text {
  text-align: center;
  margin-bottom: 60px;
}

.button {
  display: block;
  font-weight: bold;
  color: #fff;
  background-color: #004098;
  border-radius: 9999px;
  padding: 14px 120px;
  text-decoration: none;
}