.prize-list {
  flex: 1;
  background-color: #F7F7F7;
}

.prize-list-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

@media screen and (max-width: 389px) {
  .prize-list-wrap {
    gap: 15px;
    padding: 15px;
  }
}